import styled from 'styled-components';
import { getFieldFromType } from 'utils';

export const Status = styled.span`
  background-color: ${({ status }) => getFieldFromType(status, 'color')};
  color: #0a1f28;
  height: 30px;
  width: 100px;
  box-sizing: content-box;
  padding: 5px;
  text-align: center;
  float: right;
  border-radius: 50px;
  border: 2px solid ${({ status }) => getFieldFromType(status, 'color')};
  display: flex;
  justify-content: center;
  align-items: center;
`;
