export const us = {
  beServices:
    "'NORA-nora-website-prod-ecs', 'NORA-prod-api-gateway-ecs','NORA-prod-authorization-ecs','NORA-prod-notifications-api-ecs','NORA-prod-zoom-api-ecs','NORA-prod-audit-trail-api-ecs','NORA-prod-recruitment-api-ecs'",
  feService: '[US-NORA] React UI'
};

export const ap = {
  beServices:
    "'NORA-nora-website-ap-sg-ecs','NORA-nora-api-gateway-ap-sg-ecs','NORA-nora-authorization-ap-sg-ecs','NORA-nora-notifications-api-ap-sg-ecs','NORA-nora-zoom-api-ap-sg-ecs','NORA-nora-audit-trail-api-ap-sg-ecs','NORA-nora-recruitment-api-ap-sg-ecs'",
  feService: '[AP-NORA] React UI'
};

export const eu = {
  beServices:
    "'NORA-nora-website-eu-de-ecs','NORA-prod-api-gateway-eu-de-ecs','NORA-prod-authorization-eu-de-ecs','NORA-prod-notifications-api-eu-de-ecs','NORA-prod-zoom-api-eu-de-ecs','NORA-nora-audit-trail-api-eu-de-ecs','NORA-prod-recruitment-api-eu-de-ecs'",
  feService: '[EU-NORA] React UI'
};
