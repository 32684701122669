import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Status } from './styles';

const ServiceItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 10px 0px;
  border-bottom: 2px solid #cecece;
  &:last-child {
    border-bottom: none;
  }
`;

export const ServiceItem = ({ service, status }) => {
  const statusText =
    status[0].toUpperCase() + status.slice(1) === 'Default'
      ? 'Loading'
      : status[0].toUpperCase() + status.slice(1);
  return (
    <ServiceItemWrapper>
      <span>{service.name} </span>{' '}
      <span>
        <Status status={status}>{statusText} </Status>
      </span>
    </ServiceItemWrapper>
  );
};

ServiceItem.propTypes = {
  service: PropTypes.shape({ name: PropTypes.string, status: PropTypes.string }),
  status: PropTypes.string
};
