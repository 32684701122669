import { HeaderBanner, HeadlineText, Logo } from './styles';

export const Header = () => (
  <>
    <HeaderBanner>
      <Logo />
    </HeaderBanner>
    <HeadlineText>Welcome To Science37 Health Check</HeadlineText>
  </>
);
