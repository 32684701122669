import { useQuery } from 'react-query';

import * as regionalServices from '../regionalServices';
import { transformResults } from 'utils';
const fetchAvailabilityStatus = async (services) => {
  try {
    const requestOptions = {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ services: services?.beServices, feService: services?.feService })
    };
    const response = await fetch(process.env.REACT_APP_LAMBDA_URL, requestOptions);
    if (response) {
      const data = response && (await response.json());
      return transformResults(data);
    } else {
      return {
        serviceResults: {
          api: 'down',
          audit: 'down',
          authorization: 'down',
          fe: 'down',
          notifications: 'down',
          recruitment: 'down',
          website: 'down',
          zoom: 'down'
        },
        systemStatus: 'down_all'
      };
    }
  } catch (error) {
    console.log('Error while fetching!', error);
    return {
      serviceResults: {
        api: 'down',
        audit: 'down',
        authorization: 'down',
        fe: 'down',
        notifications: 'down',
        recruitment: 'down',
        website: 'down',
        zoom: 'down'
      },
      systemStatus: 'down_all'
    };
  }
};

export const useGQLQuery = (region = 'us', isIdle) => {
  return useQuery(
    ['availability', region],
    () => fetchAvailabilityStatus(regionalServices[region]),
    {
      refetchInterval: 120000,
      refetchOnWindowFocus: false,
      enabled: !isIdle
    }
  );
};
