import Select from 'react-select';
import PropTypes from 'prop-types';

const regions = [
  { value: 'eu', label: 'Europe' },
  { value: 'us', label: 'United States' },
  { value: 'ap', label: 'Asia Pacific' }
];

const colorStyles = {
  option: (provided) => ({
    ...provided,
    '&:hover': {
      backgroundColor: '#e7f3fa'
    },
    '&:focus': {
      backgroundColor: '#e7f3fa'
    },
    '&:active': {
      backgroundColor: '#e7f3fa'
    },
    '&.is-focused': {
      backgroundColor: '#e7f3fa'
    },
    backgroundColor: '#fff',
    color: '#29353b'
  }),
  control: (styles) => ({ ...styles, padding: '5px' })
};

export const SelectRegionDropdown = ({ region, setRegion }) => {
  return (
    <Select
      placeholder="Select Region"
      options={regions}
      value={region}
      onChange={(value) => setRegion(value)}
      styles={colorStyles}
    />
  );
};

SelectRegionDropdown.propTypes = {
  region: PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  setRegion: PropTypes.func
};
