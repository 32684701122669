import config from 'config.json';
const { thresholds, statusColors } = config;

export const getFieldFromType = (type, field) => {
  const { operationalColor, degradedColor, downColor, defaultColor } = statusColors;

  const typeMap = {
    operational: { color: operationalColor, message: 'All the Services are operational.' },
    degraded: { color: degradedColor, message: 'Some Services are degraded.' },
    down: { color: downColor, message: 'Some Services are down.' },
    degraded_all: { color: degradedColor, message: 'All the Services are degraded.' },
    down_all: { color: downColor, message: 'All the Services are down.' },
    default: { color: defaultColor, message: 'Fetching Services results.' }
  };
  return typeMap[type][field] || typeMap.default[field];
};

export const transformResults = (results) => {
  const resultMapping = {};
  const statusCount = { down: 0, degraded: 0 };

  results.forEach((res) => {
    const serviceStatus = getServiceStatus(res.result);
    const appIdentifier = res.appName === 'fe' ? 'fe' : res.appName?.split('-')[2];

    resultMapping[appIdentifier] = serviceStatus;

    if (serviceStatus == 'degraded') statusCount.degraded++;
    if (serviceStatus == 'down') statusCount.down++;
  });

  const systemStatus = getSystemStatus(statusCount, results.length);

  return {
    serviceResults: resultMapping,
    systemStatus
  };
};

const getSystemStatus = (statusCount, totalServices) => {
  if (statusCount.down === totalServices) return 'down_all';
  if (statusCount.down > 0) return 'down';

  if (statusCount.degraded === totalServices) return 'degraded_all';
  return statusCount.degraded > 0 ? 'degraded' : 'operational';
};

const getServiceStatus = (operationLevel) => {
  if (operationLevel < thresholds.downThreshold) return 'down';

  return operationLevel < thresholds.degradedThreshold ? 'degraded' : 'operational';
};
