import { useGQLQuery } from 'hooks/useGQLQuery';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ServiceItem } from './ServiceItem';
import { StatusMessage } from '../StatusMessage';
import { Loader } from '../Loader';

const services = [
  { name: 'Website', key: 'website' },
  { name: 'Frontend', key: 'fe' },
  { name: 'Gateway', key: 'api' },
  { name: 'Authorization', key: 'authorization' },
  { name: 'Notifications', key: 'notifications' },
  { name: 'Zoom', key: 'zoom' },
  { name: 'Audit Trail', key: 'audit' },
  { name: 'Recruitment', key: 'recruitment' }
];

const ServicesWrapper = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: auto;
`;

const LastUpdated = styled.div`
  color: rgb(170, 170, 170);
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const ServicesList = ({ isIdle, region }) => {
  const { data, dataUpdatedAt, isLoading } = useGQLQuery(region, isIdle);
  const [systemStatus, setSystemStatus] = useState('default');

  useEffect(() => {
    setSystemStatus(data?.systemStatus || 'default');
    return () => {
      setSystemStatus('down_all');
    };
  }, [data?.systemStatus]);

  if (isLoading) return <Loader />;

  return (
    <>
      <StatusMessage systemStatus={systemStatus} />
      <ServicesWrapper>
        {services.map((service) => {
          return (
            <ServiceItem
              key={service.name}
              service={service}
              status={data?.serviceResults ? data?.serviceResults[service.key] : 'default'}
            />
          );
        })}
      </ServicesWrapper>
      {!isLoading && (
        <LastUpdated>
          <p>Updated At: {new Date(dataUpdatedAt).toLocaleString('en-US')}</p>
        </LastUpdated>
      )}
    </>
  );
};

ServicesList.propTypes = {
  isIdle: PropTypes.bool,
  region: PropTypes.string
};
