import styled from 'styled-components';
import { getFieldFromType } from 'utils';

export const StatusMessageContainer = styled.div`
  background-color: ${({ type }) => getFieldFromType(type, 'color')};
  padding: 14px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  margin: 5px 0px;
  border-left: 4px solid;
  border-left-color: #00000062;
`;

export const StatusMessageText = styled.p`
  color: #0a1f28;
  font-size: 18px;
`;
