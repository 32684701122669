import styled from 'styled-components';
import banner from 'assets/images/login-background.svg';
import S37Logo from 'assets/images/science-37-logo.svg';

export const HeaderBanner = styled.div`
  background-image: url(${banner});
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15px 30px;
`;

export const HeadlineText = styled.h1`
  margin: 10px 30px;
  color: #0a1f28;
`;

export const Logo = styled.div`
  background-image: url(${S37Logo});
  background-size: cover;
  background-repeat: no-repeat;
  height: 31px;
  width: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
`;
