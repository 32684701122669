import styled from 'styled-components';

export const Spinner = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  width: 60px;
  height: 60px;
  border: 6px solid #72c600;
  border-top: 6px solid transparent;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
`;

export const SpinnerContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
`;
