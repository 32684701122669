import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useIdleTimer } from 'react-idle-timer';

import Main from 'pages/Main';
import { useState } from 'react';

const queryClient = new QueryClient();

const App = () => {
  const [isIdle, setIsIdle] = useState(false);

  const onIdle = () => {
    setIsIdle(true);
  };

  const onActive = () => {
    setIsIdle(false);
  };

  useIdleTimer({ onIdle, onActive, timeout: 120000, debounce: 250 });

  return (
    <QueryClientProvider client={queryClient}>
      <Main isIdle={isIdle} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
