import PropTypes from 'prop-types';
import { StatusMessageContainer, StatusMessageText } from './styles';
import { getFieldFromType } from 'utils';

export const StatusMessage = ({ systemStatus = 'down_all' }) => (
  <StatusMessageContainer type={systemStatus}>
    <StatusMessageText>{getFieldFromType(systemStatus, 'message')}</StatusMessageText>
  </StatusMessageContainer>
);

StatusMessage.propTypes = {
  systemStatus: PropTypes.string
};
