import { Header } from 'components/Header';
import { useState } from 'react';
import { SelectRegionDropdown } from 'components/SelectRegionDropdown';
import { ServicesList } from 'components/ServicesList';
import { CenterWrapper, MainWrapper } from './styles';
import PropTypes from 'prop-types';

const Home = ({ isIdle }) => {
  const [region, setRegion] = useState({ value: 'us', label: 'United States' });

  return (
    <>
      <Header />
      <MainWrapper>
        <CenterWrapper>
          <SelectRegionDropdown region={region} setRegion={setRegion} />
          <ServicesList isIdle={isIdle} region={region.value} />
        </CenterWrapper>
      </MainWrapper>
    </>
  );
};

export default Home;

Home.propTypes = {
  isIdle: PropTypes.bool
};
